// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-featured-menus-js": () => import("./../../../src/pages/featured-menus.js" /* webpackChunkName: "component---src-pages-featured-menus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-products-all-dips-image-js": () => import("./../../../src/pages/products/all-dips-image.js" /* webpackChunkName: "component---src-pages-products-all-dips-image-js" */),
  "component---src-pages-products-baba-ghanoush-image-js": () => import("./../../../src/pages/products/baba-ghanoush-image.js" /* webpackChunkName: "component---src-pages-products-baba-ghanoush-image-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-just-hummus-image-js": () => import("./../../../src/pages/products/just-hummus-image.js" /* webpackChunkName: "component---src-pages-products-just-hummus-image-js" */),
  "component---src-pages-products-labneh-image-js": () => import("./../../../src/pages/products/labneh-image.js" /* webpackChunkName: "component---src-pages-products-labneh-image-js" */),
  "component---src-pages-products-muhammara-image-js": () => import("./../../../src/pages/products/muhammara-image.js" /* webpackChunkName: "component---src-pages-products-muhammara-image-js" */),
  "component---src-pages-products-plain-labneh-image-js": () => import("./../../../src/pages/products/plain-labneh-image.js" /* webpackChunkName: "component---src-pages-products-plain-labneh-image-js" */),
  "component---src-pages-products-tzatziki-image-js": () => import("./../../../src/pages/products/tzatziki-image.js" /* webpackChunkName: "component---src-pages-products-tzatziki-image-js" */)
}

